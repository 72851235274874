/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import React from 'react'

const UsersListSearchComponent = ({placeholder = 'Search user', searchElements = []}) => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const renderSearch = () => {
    return searchElements.map((eachSearch: any, eachIndex: number) => {
      switch (eachSearch.type) {
        case '':
          return (
            <>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder={placeholder}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />{' '}
            </>
          )
        case 'select':
          return (
            <React.Fragment key={eachIndex + 1 + ''}>
              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  onChange={(e) =>
                    updateState({
                      type: e.target.value,
                      initialApi: `categories/${e.target.value}/${
                        eachSearch?.queryType || 'items'
                      }`,
                      ...initialQueryState,
                    })
                  }
                  defaultValue={'63f77b6c7dc37d521ee98400'}
                >
                  {eachSearch.options.map((eachOption, eachInd) => (
                    <option key={eachInd + 1 + ''} value={eachOption.value}>
                      {eachOption.label}
                    </option>
                  ))}
                </select>
              </div>
            </React.Fragment>
          )
        default:
          break
      }
    })
  }
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        {(searchElements.length > 0 && renderSearch()) || null}
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}
