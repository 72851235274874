import {toAbsoluteUrl} from '../AssetHelpers'
import {generateAddress} from '../addressGenerator'
import {configCategoryMap} from '../dataExamples'

const configMapData = configCategoryMap

function clipText(text, maxLength = 17, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
}

const accessNestedProperty = (obj, path) => {
  return path.split('[').reduce((acc, key) => {
    return acc && acc[key.replace(']', '')]
  }, obj)
}

const checkIsTrueOrFalse = (data, name) => {
  return data.includes(name) ? 'Yes' : 'No'
}

export const returnData = (mapData, user, ref?: any, itemIdForUpdate?: any) => {
  const handleClick = (image) => {
    ref?.current?.open(image, itemIdForUpdate)
  }
  if (mapData.includes('[')) return accessNestedProperty(user, mapData)
  switch (mapData) {
    case 'operationDaysList':
      const configMapDay: any = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
      return (
        <div className='row'>
          {user?.companyDetails?.operatingDays?.map((eachData, idx) => {
            return (
              <div className={`${eachData === 'true' ? 'fw-bold' : ''} col col-lg-1`}>
                {configMapDay[idx]}
              </div>
            )
          })}
        </div>
      )
    case 'amountPaid':
      return (+user?.analytics?.totalPayment)?.toFixed()
    case 'avgCollection':
      return (+user?.analytics?.avgCollection)?.toFixed()
    case 'customerType':
      return (
        <div>
          {user[mapData] === 'COLLECTION_STORE' ? 'COMMUNITY COLLECTOR' : user[mapData] || ''}
        </div>
      )
    case 'categoryMap':
      return <div>{configMapData[user.categoryId] || ''}</div>
    case 'format':
    case 'contactDetail':
      return (
        <div>
          <div>{user.personalDetails.mobile}</div>
          <div>{user.personalDetails.email}</div>
        </div>
      )
    case 'orderDetailsItemName':
      return (
        <span
          className={`text-${
            user?.orderDetails?.itemName === 'BOP BLUE' ? 'primary' : 'success'
          } fs-7 fw-bold`}
        >
          {user?.orderDetails?.itemName}
        </span>
      )
    case 'pushToBlockChain':
      return (
        <img
          src={toAbsoluteUrl(
            `/media/products/${user[mapData] ? 'blockchain.png' : 'no-entry.png'}`
          )}
          height={'35px'}
          alt={user.name}
        />
      )
    case 'wastage':
      return <span className='text-danger fs-7 fw-bold'>{user.wastage}</span>
    case 'orderDetailsWeight':
      return <span className='text-primary fs-7 fw-bold'>{user?.orderDetails?.weight}</span>
    case 'orderDetailsDeduction':
      return <span className='text-danger fs-7 fw-bold'>{user?.orderDetails?.weightDeduction}</span>
    case 'netRecivedCalculate':
      return (
        <span className='text-success fs-7 fw-bold'>
          {user?.orderDetails?.weight - user?.orderDetails?.weightDeduction || 0}
        </span>
      )
    case 'paymentDetailsAmount':
      return (
        <span className='badge badge-light-info fs-7 fw-bold'>{user?.paymentDetails?.amount}</span>
      )
    case 'pickUpVehicleNumber':
      return (
        <span className='text-success fs-7 fw-bold'>
          {user?.pickupInfo?.vehicleDetails?.registrationNo || ''}
        </span>
      )
    case 'quantityDeposit':
      const totalQuantity =
        user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) || 0
      return totalQuantity
    case 'noOfBags':
      return user?.orderDetails?.quantity || ''
    case 'country':
    case 'city':
    case 'street':
    case 'state':
    case 'landmark':
    case 'zipCode':
    case 'latitute':
    case 'longitute':
      return user?.address?.[mapData] || user?.customerInfo?.address?.[mapData] || ''
    case 'geoLocation':
      return user.address.latitute + ' , ' + user.address.longitute
    case 'materialQuantity':
      const materialQuantity = user?.details?.map((x) => x.quantity) || ''
      return materialQuantity.join(', ')
    case 'transportType':
      return (
        <span
          className={`text-${user.transportType === 'OWN' ? 'primary' : 'success'} fs-7 fw-bold`}
        >
          {user.transportType}
        </span>
      )
    case 'outputProductionItem':
      const desData = user?.productionItemDetails.map((x) => `${x.itemName}-${x.quantity}`) || []
      // return desData.join(', ')
      return (
        <div className='m-w-350px' style={{minWidth: '350px'}}>
          {desData.join(', ')}
        </div>
      )
    case 'customAddress':
      const customeAddr = generateAddress(user?.hubInfo?.address, ['city', 'state', 'country'])
      return (
        <div data-bs-toggle='tooltip' title={customeAddr}>
          {clipText(customeAddr, 35)}
        </div>
      )
    case 'materialDispatched':
      const materialDispatched = user?.details?.map((x) => x.itemName) || ''
      return <span className='text-primary fs-7 fw-bold'>{materialDispatched.join(', ')}</span>
    case 'plantAddress':
      const addressPlant = generateAddress(user?.hubInfo?.address)
      return (
        <div data-bs-toggle='tooltip' title={addressPlant}>
          {clipText(addressPlant, 25)}
        </div>
      )
    case 'address':
      const addressConcat = generateAddress(
        user.address ||
          user.pickupInfo?.address ||
          user.dropOffPointInfo?.address ||
          user?.customerInfo?.address ||
          user?.hubInfo?.address
      )
      return (
        <div data-bs-toggle='tooltip' title={addressConcat}>
          {clipText(addressConcat, 25)}
        </div>
      )
    case 'countryCity':
      const cityCount = user.customerInfo.address.city + ', ' + user.customerInfo.address.country
      return cityCount
    case 'manager':
      return user?.companyDetails?.name || ''
    case 'pickupInfoName':
      return user?.pickupInfo?.name || ''
    case 'paymentAt':
      return user?.paymentDetails?.paymentAt
        ? new Date(+user?.paymentDetails?.paymentAt).toLocaleDateString()
        : '-'
    case 'inspectionCompletedAT':
      return user?.materialInspectionInfo?.inspectionCompletedAT
        ? new Date(+user?.materialInspectionInfo?.inspectionCompletedAT).toLocaleDateString()
        : '-'
    case 'receivingAt':
      return user?.materialRecInfo
        ? new Date(+user?.materialRecInfo?.receivingAt).toLocaleDateString()
        : '-'
    case 'pickupCompletedAt':
      return (
        (user?.pickupInfo?.pickupCompletedAt &&
          new Date(+user?.pickupInfo?.pickupCompletedAt || new Date()).toLocaleDateString()) ||
        '-'
      )
    case 'pickupDate':
    case 'productionId':
      return new Date(
        +user.productionId || +user?.pickupInfo?.pickupDateTime || new Date()
      ).toLocaleDateString()
    case 'createdAt':
      return new Date(+user?.createdAt || new Date()).toLocaleDateString()
    case 'OI_COLLECTOR':
    case 'MATERIAL_RECEIVING':
    case 'MATERIALINSPECTION':
    case 'PRODUCTION':
    case 'DISPATCH':
    case 'LOGISTICS':
    case 'INVENTORY':
    case 'MY_TRIPS':
      return checkIsTrueOrFalse(user.permissions, mapData)
    case 'selfie':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              onClick={() =>
                handleClick(
                  user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)
                )
              }
              src={user?.personalDetails?.profileImage || toAbsoluteUrl(`/media/avatars/blank.png`)}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    case 'avatar':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              onClick={() =>
                handleClick(user?.kycDocument?.docUrl || toAbsoluteUrl(`/media/avatars/blank.png`))
              }
              src={user?.kycDocument?.docUrl || toAbsoluteUrl(`/media/avatars/blank.png`)}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    default:
      return typeof user[mapData] === 'boolean'
        ? user[mapData]
          ? 'Yes'
          : 'No'
        : user?.[mapData] || user?.personalDetails?.[mapData] || ''
  }
}
