import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'

interface userListProps {
  label: String
}

const UsersListToolbar = ({label}: userListProps) => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {label && (
        <button
          type='button'
          style={{backgroundColor: '#428cb4'}}
          className='btn btn-primary'
          onClick={openAddUserModal}
        >
          <KTIcon iconName='plus' className='fs-2' />
          {label}
        </button>
      )}
    </div>
  )
}

export {UsersListToolbar}
