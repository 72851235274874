/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser, updateUser} from '../../core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {DeleteConfirmationModal} from './DeleteModal'
import {successToast, errorToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {useLocation} from 'react-router'
import GenerateQrCode from './GenerateQrCode'

type Props = {
  id: any
  action: any
}

const UserActionsCell: FC<Props> = ({id, action = ['edit']}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const {state} = useQueryRequest()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = (showView = false, showTable = false) => {
    setItemIdForUpdate({...id, showView, showTable})
  }

  const deleteConfirmation = () => {
    setShowDeleteModal(true)
  }

  const {pathname} = useLocation()
  const getQueryForDelete = () => {
    if (pathname.includes('role-master')) return `roles/${id.id}/status/DELETED`
    if (pathname.includes('brand')) return `brands/${id.id}/status/DELETED`
    if (pathname.includes('drop-off-point')) return `users/${id.id}/status/DELETED`
    if (pathname.includes('ocean-hub-directory')) return `users/${id.id}/status/DELETED`
    if (pathname.includes('process-name')) return `process/${id.id}/status/DELETED`
    if (pathname.includes('quality-remarks')) return `remarks/${id.id}/status/DELETED`
    if (pathname.includes('category')) return `categories/${id.id}/status/DELETED`
    if (pathname.includes('raw-material-type')) return `items/${id.id}/status/DELETED`
    if (pathname.includes('finished-goods-type')) return `items/${id.id}/status/DELETED`
    if (pathname.includes('downtime')) return `downtimes/${id.id}/status/DELETED`
    if (pathname.includes('shift')) return `shifts/${id.id}/status/DELETED`
    if (pathname.includes('delivery-terms')) return `deliveryterms/${id.id}/status/DELETED`
    if (pathname.includes('unit-measurements')) return `uams/${id.id}/status/DELETED`
    if (pathname.includes('recycler-code')) return `users/${id.id}/status/DELETED`
    // unit-measurements

    return ''
  }

  const deleteItem = useMutation(() => deleteUser(getQueryForDelete()), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (dat) => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      setShowDeleteModal(false)
      successToast('Deleted')
    },
    onError: (error: any) => {
      errorToast(error?.message)
    },
  })

  const toggleStatus = useMutation(
    () =>
      updateUser(
        {},
        `user/${id.id}/status?type=${state.type}&status=${
          id.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
        }`
      ),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      },
    }
  )
  const [showQrCode, setShowQrCode] = useState(false)
  const issAssignCleanUps = pathname.includes('assign-clean-ups')
  return (
    <>
      {showDeleteModal && (
        <DeleteConfirmationModal
          confirm={async () => await deleteItem.mutateAsync()}
          close={() => setShowDeleteModal(false)}
        />
      )}
      {showQrCode && <GenerateQrCode data={id} close={() => setShowQrCode(false)} />}

      <div className='align-items-center min-w-125px'>
        {(action.includes('view') && (
          <a
            onClick={() => openEditModal(true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='eye' className='fs-3' />
          </a>
        )) ||
          false}

        {/* {(action.includes('download') && (
          <>
            <a
              onClick={() => handleDownload()}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='file-down' className='fs-3' />
            </a>
          </>
        )) ||
          false} */}
        {((action.includes('view_certificate') || action.includes('view-table')) && (
          <a
            onClick={() => openEditModal(false, true)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='eye' className='fs-3' />
          </a>
        )) ||
          false}
        {(action.includes('edit') && (
          <a
            // onClick={() => openEditModal()}
            onClick={() => (id?.sponsorName ? false : openEditModal())}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </a>
        )) ||
          false}

        {action.includes('delete') && action.includes('edit') && (
          <a
            // onClick={async () => await deleteItem.mutateAsync()}
            onClick={() => deleteConfirmation()}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTIcon iconName='trash' className='fs-3' />
          </a>
        )}
        {(action.includes('qr') && (
          <>
            {issAssignCleanUps && id?.sponsorDetails?.length ? (
              <a
                onClick={() => setShowQrCode(true)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTIcon iconName='code' className='fs-3' />
              </a>
            ) : (
              ''
            )}
            {!issAssignCleanUps && (
              <a
                onClick={() => setShowQrCode(true)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTIcon iconName='code' className='fs-3' />
              </a>
            )}
          </>
        )) ||
          false}
        {(action.includes('toggle') && (
          <div className='form-check form-check-solid form-switch fv-row'>
            <input
              className='form-check-input'
              type='checkbox'
              id='allowmarketing'
              defaultChecked={id.status === 'ACTIVE' ? true : false}
              onChange={async () => {
                await toggleStatus.mutateAsync()
              }}
            />
          </div>
        )) ||
          false}
      </div>
    </>
  )
}

export {UserActionsCell}
