import {Column} from 'react-table'
import {ColumnGenerators} from '../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Profile Pic',
    value: 'kycDocument',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Country',
    value: 'address[country]',
  },
  {
    label: 'Role',
    value: 'customerType',
  },
  {
    label: 'Total Collected',
    value: 'analytics[totalQtyCollected]',
  },
  {
    label: 'Bags',
    value: 'analytics[plasticCollected]',
  },
  {label: 'Amount Paid', value: 'amountPaid'},
  {label: 'Avg Collection', value: 'avgCollection'},
]

const roleColumns: ReadonlyArray<Column<any>> = ColumnGenerators(header)

export {roleColumns}
