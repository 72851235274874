import {
  ListViewProvider,
  useListView,
} from '../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UsersListHeader} from '../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {UsersTable} from '../data-administration/data-admininstration-list/table/UsersTable'
import {KTCard, initialQueryState} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useQuery} from 'react-query'
import {getUserById} from '../data-administration/data-admininstration-list/core/_requests'
import {useQueryRequest} from '../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {useEffect, useState} from 'react'

const LeaderboardList = () => {
  // const {data} = useQuery(
  //   `users?page=1&size=10&type=HUB`,
  //   () => getUserById('', 'users?page=1&size=10&type=HUB'),
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //       console.warn(err)
  //     },
  //   }
  // )
  const {updateState} = useQueryRequest()

  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  // useEffect(() => {
  //   if (data && data.length) {
  //     let dropdownData: any = []
  //     data.map((eachData) => {
  //       return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
  //     })
  //     setCategoriesList(dropdownData)
  //     updateState({
  //       enabled: true,
  //       initialApi: `stock/RAW_MATERIAL?facility=${dropdownData[0].value}`,
  //       ...initialQueryState,
  //     })
  //   }
  // }, [data])

  const searchElements = [
    {
      type: 'select',
      value: 'select',
      options: categoriesList,
    },
  ]

  return (
    <KTCard>
      {/* <UsersListHeader searchElements={searchElements} placeholder='Search Brand' label='' /> */}
      <UsersTable columnProps={roleColumns} />
    </KTCard>
  )
}

const LeaderboardWrapper = () => (
  <QueryRequestProvider initialValue={{initialApi: 'users?type=CUSTOMER'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <LeaderboardList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {LeaderboardWrapper}
