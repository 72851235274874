// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserInfoCell'
import {UserLastLoginCell} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserLastLoginCell'
import {RoleTable} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserTwoStepsCell'
import {UserActionsCell} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserActionsCell'
import {UserSelectionCell} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserSelectionCell'
import {UserCustomHeader} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserCustomHeader'
import {UserSelectionHeader} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/UserSelectionHeader'
import {boolean} from 'yup'
import {useQueryRequest} from '../../app/modules/apps/data-administration/data-admininstration-list/core/QueryRequestProvider'
import {SerialNumber} from '../../app/modules/apps/data-administration/data-admininstration-list/table/columns/SerialNumberIndex'

const generator = (data) => {
  return data.map((eachHeader, idx) => {
    if (eachHeader?.action?.length > 0) {
      return {
        Header: (props) => (
          <UserCustomHeader tableProps={props} title='Actions' className='min-w-100px' />
        ),
        id: idx + 1 + '',
        Cell: ({...props}) => (
          <UserActionsCell
            id={
              eachHeader?.action.includes('id')
                ? props.data[props.row.index].id
                : props.data[props.row.index]
            }
            action={eachHeader?.action}
          />
        ),
      }
    } else
      return {
        Header: (props) => (
          <UserCustomHeader
            tableProps={props}
            title={eachHeader.label}
            className={
              ['S.N', 'ID'].includes(eachHeader.label)
                ? 'min-w-25px'
                : ['Operating Days', 'Total Collected'].includes(eachHeader.label)
                ? 'min-w-150px'
                : [
                    'Collector',
                    'Auditor',
                    'Processing Time',
                    'Customer Name',
                    'Collection Point',
                    'Town/ City',
                    'INCOTERMS',
                    'Address',
                    'Name',
                    'Received At',
                    'Pick Up By',
                  ].includes(eachHeader.label)
                ? 'min-w-225px'
                : 'min-w-125px'
            }
          />
        ),
        id: idx + 1 + '',
        Cell: ({...props}) => {
          return ['S.N', 'ID'].includes(eachHeader.label) ? (
            <SerialNumber number={props.row.index} />
          ) : (
            // props.row.index + 1
            <UserInfoCell
              showRoleTable={eachHeader?.action?.includes('roleTable')}
              mapData={eachHeader.value}
              user={props.data[props.row.index]}
              showImageOnly={['avatar', 'kycDocument'].includes(eachHeader.value)}
            />
          )
        },
      }
  })
}

// const ColumnGenerators: ReadonlyArray<Column<User>> = generator()
const ColumnGenerators = (header) => {
  return generator(header)
}

export {ColumnGenerators}
