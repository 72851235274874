/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useRef, useEffect} from 'react'
import {
  generateAddress,
  configCategoryMap,
  toAbsoluteUrl,
} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import {returnData} from '../../../../../../../_metronic/helpers/components/returnDataHelper'
import ImageLightBox from '../../../../../../../_metronic/helpers/components/ImageLightBox'
import {useListView} from '../../core/ListViewProvider'
type Props = {
  user: any
  showImage?: boolean
  mapData?: string
  showImageOnly?: boolean
}

const UserInfoCell: FC<Props> = ({
  user,
  showImageOnly = false,
  showImage = false,
  mapData = '',
}) => {
  const lightBoxRef = useRef<any>(null)
  const {itemIdForUpdate} = useListView()

  let imageUrl: any = ''
  if (showImageOnly) {
    imageUrl =
      (mapData === 'kycDocument' && user?.kycDocument?.docUrl) ||
      user?.personalDetails?.profileImage ||
      user?.icon ||
      user.categoryImage ||
      toAbsoluteUrl(`/media/avatars/blank.png`)
  }
  return (
    <div className='align-items-center'>
      {showImageOnly && (
        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
          <div className='symbol-label '>
            <a href='#' className='text-gray-800 text-hover-primary mb-1'>
              <img
                src={imageUrl}
                alt={user.name}
                className='w-100'
                onClick={() => lightBoxRef?.current?.open(imageUrl, itemIdForUpdate)}
              />
            </a>
          </div>
        </div>
      )}
      {!showImageOnly && <div className='text-gray-800 mb-1'>{returnData(mapData, user)}</div>}
      {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
    </div>
  )
}

// function clipText(text, maxLength = 17, clipper = '...') {
//   if (!text) return text
//   return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
// }

export {UserInfoCell}
